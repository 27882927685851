












































































































import { Component, Vue, Prop } from "vue-property-decorator"
import House from "~/models/House"
import ClassifiedAd from "~/models/ClassifiedAd"
import Verification from "~/models/Verification"
import { state } from "@/store"
import snackbar from "~/plugins/snackbar"
import router from "@/router"
import HouseForm from "@/components/form/HouseForm.vue"

@Component({
  components: {
    HouseForm: () => import("@/components/form/HouseForm.vue"),
    HouseMap: () => import("@/components/house/HouseMap.vue"),
    HouseCard: () => import("@/components/house/HouseCard/HouseCard.vue"),
    ValidateVerification: () =>
      import("@/components/modals/ValidateVerification.vue"),
  },
})
export default class HouseVerification extends Vue {
  @Prop({ required: false, default: new House() }) house!: House
  @Prop({ default: false }) loading!: boolean
  verificationLoading = false

  get finishVerificationText() {
    return this.verificationIsInProgress
      ? "Terminer la vérification"
      : "Modifier la vérification"
  }

  get finishVerificationTooltipText() {
    return this.verificationIsInProgress
      ? "Assurez vous d'avoir renseigné les champs obligatoires et d'avoir associer un agent " +
          "au bien pour finaliser la vérification et avertir les autres chasseurs que vous avez vérifié ce bien"
      : "Signaler une modification et évaluez la prise de contact avec l'agent"
  }

  get verificationIsInProgress() {
    return this.house.verification_status == "Verification in progress"
  }

  get houseTrendText() {
    if (!this.house) return null
    const hc_interested_count = this.house.hc_interested_count
    const customer_interested_count = this.house.customer_interested_count
    let text = ""
    if (hc_interested_count)
      text = `${hc_interested_count} ${
        hc_interested_count > 1 ? "chasseurs" : "chasseur"
      }`
    if (customer_interested_count)
      text = `${text} et ${customer_interested_count} ${
        customer_interested_count > 1 ? "clients" : "client"
      }`
    text = `${text} ${
      hc_interested_count && customer_interested_count
        ? "sont potentiellement intéressés"
        : "est potentiellement intéressé"
    }`
    text = `${text} par ce bien`
    return text
  }

  async saveVerification() {
    this.verificationLoading = true
    let verification = new Verification()
    if (this.house.verification) verification.assign(this.house.verification)
    else {
      verification.house_id = this.house.id
      verification.deal_id = state.currentDealId()
    }
    verification.done = false
    await verification.saveAndAssign()
    await this.house.fetch()
    this.house.verification_status = "Verification in progress"
    state.notifications.fetch()
    snackbar({
      color: "success",
      content: `Votre vérification est en cours !`,
    })
    this.verificationLoading = false
  }

  async createHouse() {
    const houseForm = this.$refs.houseForm as HouseForm
    const valid = houseForm.validateForm()
    if (valid) {
      let classifiedAd = new ClassifiedAd()
      let response = await classifiedAd.save({
        data: { classified_ad: this.house },
      })
      snackbar({
        color: "success",
        content: `Votre bien est en cours de création ! Veuillez patientez, la page va se recharger`,
      })
      this.house.id = response.house.id
      setTimeout(this.reloadHouse, 2000)
    }
  }

  async cancelVerification() {
    this.verificationLoading = true
    const success = await this.house.verification?.delete()
    if (success) {
      snackbar({
        color: "success",
        content: `La vérification du bien a bien été annulée`,
      })
    }
    this.house.verification = null
    this.verificationLoading = false
  }

  reloadHouse() {
    const { dealId } = this.$route.params
    router.push({
      path: `/deals/${dealId}/sent/house/${this.house.id}/description`,
    })
  }
}
